import * as React from 'react';

function Documents(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            width={123.89}
            height={123.89}
            viewBox="0 0 123.89 123.89"
            {...props}
        >
            <defs>
                <style>
                    {
                        '.cls-1{fill:#db143c;}.cls-2{stroke-width:4.04px;}.cls-2,.cls-3{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;}.cls-3{stroke-width:6.74px;}'
                    }
                </style>
            </defs>
            <g id="header">
                <g>
                    <path
                        className="cls-1"
                        d="M123.89,61.95c0,34.21-27.73,61.95-61.95,61.95S0,96.16,0,61.95,27.73,0,61.95,0s61.95,27.73,61.95,61.95"
                    />
                    <rect
                        className="cls-1"
                        x="22.87"
                        y="30.02"
                        width="68.77"
                        height="48.65"
                        transform="translate(-14.04 88.22) rotate(-68.91)"
                    />
                    <rect
                        className="cls-3"
                        x="26.92"
                        y="28.67"
                        width="68.77"
                        height="48.65"
                        transform="translate(-10.2 91.13) rotate(-68.91)"
                    />
                    <polyline
                        className="cls-3"
                        points="91.44 42.43 103.58 47.1 78.83 111.27 33.44 93.77 38.36 81"
                    />
                    <line
                        className="cls-2"
                        x1="55.71"
                        y1="29.56"
                        x2="82.32"
                        y2="39.62"
                    />
                    <line
                        className="cls-2"
                        x1="51.09"
                        y1="41.36"
                        x2="77.7"
                        y2="51.43"
                    />
                    <line
                        className="cls-2"
                        x1="45.96"
                        y1="52.65"
                        x2="72.57"
                        y2="62.72"
                    />
                    <line
                        className="cls-2"
                        x1="41.85"
                        y1="63.95"
                        x2="68.46"
                        y2="74.01"
                    />
                </g>
            </g>
        </svg>
    );
}

export default Documents;
