/**
 * The application color schema
 */
const theme: {[key: string]: string} = {
    black: '#000',
    white: '#fff',

    redPurpleGradient:
        '0deg, hsla(267, 100%, 22%, 1) 66%, hsla(342, 100%, 45%, 1) 100%',

    greyDark: '#b6b6b6',
    greySlightlyDark: '#e2d6dc',
    grey: '#f2ebee',
    greyLight: '#f1f0f6',
    greyOpaque: 'rgba(96, 96, 90, 0.65)',

    purpleDark: '#201547',
    purple: '#330072',
    purpleLight: '#653379',

    purpleOpaque: 'rgba(51, 0, 114, 0.9)',
    purpleWash: 'rgb(225 212 225)',

    orangeDark: '#E87722',
    orange: '#ED8B00',
    orangeLight: '#F1B434',

    yellow: '#F1B434',

    blue: '#14005B',
    blueDark: 'rgb(32 21 71)',

    green: '#8ABD24',
    red: '#E40046',

    boxShadow: `rgb(32 21 71 / 11%) 2px 4px 10px 2px`,

    fullhd: '1200px',
    desktop: '992px',
    tablet: '768px',
    largeMobile: '620px',
    mobile: '576px',
    mediumMobile: '500px',
    smallmobile: '400px',

    borderSize: '10px',
    navBorderSize: '2px',

    desktopMaxWidth: '1080px',

    baseFontSize: '20px',
    navFontSize: '18px',
    lineHeight: '1.2em',
    headingFontWeight: '500',

    accordionAnimationTime: '0.5s',
    accordionTransitionType: 'ease-in-out',

    textUnderlineOffset: '15px',
    textUnderlineOffsetTouch: '0px',
};

export const mq = {
    smallmobile: `@media only screen and (max-width: ${theme.smallmobile})`,
    mediumMobile: `@media only screen and (max-width: ${theme.mediumMobile})`,
    mobile: `@media only screen and (min-width: ${theme.mobile})`,
    largeMobile: `@media only screen and (max-width: ${theme.largeMobile})`,
    tablet: `@media only screen and (min-width: ${theme.tablet})`,
    touch: `@media only screen and (max-width: ${theme.desktop})`,
    desktop: `@media only screen and (min-width: ${theme.desktop})`,
    fullhd: `@media only screen and (min-width: ${theme.fullhd})`,

    /**
     * Print media quires
     */
    print: '@media print',
    screen: '@media screen',
};

export default theme;
