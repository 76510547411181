import * as React from 'react';
import {jsx} from '@emotion/react';
import {Global, css} from '@emotion/react';
import theme, {mq} from '@app/theme';
import Helmet from 'react-helmet';

interface LayoutProps {
    backgroundColor: string;
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
    backgroundColor,
    children,
}) => {
    return (
        <div
            css={{
                width: '100%',
                minHeight: '100%',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link rel="preconnect" href="https://use.typekit.net" />
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/lza7xao.css"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="//www.forwardwithdementia.org/wp-content/themes/Divi-FWD/toolkit-article-styleTEMP.css"
                    rel="stylesheet"
                />
            </Helmet>
            <Global
                styles={css`
                    html,
                    body,
                    #___gatsby,
                    #gatsby-focus-wrapper {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        font-size: ${theme.baseFontSize};
                    }

                    h1 {
                        font-size: 38px;

                        ${mq.smallmobile} {
                            font-size: 32px;
                            word-break: break-word;
                        }
                    }

                    p {
                        ${mq.smallmobile} {
                            font-size: 16px;
                            word-break: break-word;
                        }
                    }

                    h2,
                    h3 {
                        font-size: 26px;

                        ${mq.mobile}: {
                            font-size: 28px;
                        }
                    }

                    h4,
                    h5,
                    h6 {
                        font-size: 25px;
                    }

                    h1,
                    h1 *,
                    h2,
                    h2 *,
                    h3,
                    h3 *,
                    h4,
                    h4 *,
                    h5,
                    h5 *,
                    h6,
                    h6 * {
                        font-family: 'Poppins', sans-serif;
                        font-weight: ${theme.headingFontWeight};
                    }

                    * {
                        font-family: 'Poppins', sans-serif;
                        line-height: ${theme.lineHeight};
                        box-sizing: content-box;
                    }
                `}
            />
            <main
                css={css`
                    background-color: ${backgroundColor};
                    display: flex;
                    flex-grow: 1;
                `}
            >
                {children}
            </main>
        </div>
    );
};

export default Layout;
