/** @jsx jsx */
import theme from '@app/theme';
import {jsx, css} from '@emotion/react';
import * as React from 'react';

export interface DesktopWrapperProps {
    /**
     * The emotion css prop to pass to the wrapper
     */
    css?: any;
}

const DesktopWrapper: React.FC<
    React.PropsWithChildren<DesktopWrapperProps>
> = ({children, css}) => (
    <div
        css={{
            ...(css || {}),
            [`@media only screen and (min-width: ${theme.desktop})`]: {
                maxWidth: theme.desktopMaxWidth,
                margin: '0 auto',
            },
        }}
    >
        {children}
    </div>
);

export default DesktopWrapper;
