/** @jsx jsx */
import Documents from '@app/icons/documents';
import {Browsers, useBrowserContext} from '@app/lib/browser-context';
import {ReactFCWithChildren} from '@app/lib/types';
import theme from '@app/theme';
import {jsx} from '@emotion/react';
import * as React from 'react';
import DesktopWrapper from './desktop-wrapper';
import Layout from './layout';
import SEO from './seo';

export const IeWarning: ReactFCWithChildren = ({children}) => {
    const browser = useBrowserContext();

    if (browser.is(Browsers.InternetExplorer)) {
        return (
            <Layout backgroundColor={theme.purple}>
                <div
                    css={{
                        backgroundColor: theme.purple,
                        color: theme.white,
                        width: '100%',
                        a: {color: theme.orangeLight},
                    }}
                >
                    <SEO title="Unsupported Browser" lang="en" />
                    <DesktopWrapper>
                        <div
                            css={{
                                padding: '60px 60px 0 60px',
                                textAlign: 'center',
                                minHeight: '100%',
                            }}
                        >
                            <Documents />
                            <h1>
                                Forward with dementia toolkit does not support
                                this web browser.
                            </h1>
                            <h2>
                                Please make sure to use the latest version of{' '}
                                <a
                                    href="https://www.google.com/chrome/"
                                    target="blank"
                                >
                                    Chrome
                                </a>
                                ,{' '}
                                <a
                                    href="https://www.mozilla.org/firefox/"
                                    target="blank"
                                >
                                    Firefox
                                </a>
                                ,{' '}
                                <a
                                    href="https://www.apple.com/safari/"
                                    target="blank"
                                >
                                    Safari
                                </a>
                                , or{' '}
                                <a
                                    href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                                    target="blank"
                                >
                                    Microsoft Edge
                                </a>{' '}
                                for the best experience.
                            </h2>
                        </div>
                    </DesktopWrapper>
                </div>
            </Layout>
        );
    }

    return children as React.ReactElement;
};
