import React from 'react';
import {BrowserContextProvider} from '@app/lib/browser-context';
import {IeWarning} from './ie-warning';
import {ReactFCWithChildren} from '@app/lib/types';

export const RootWrapper: ReactFCWithChildren = ({children}) => {
    return (
        <BrowserContextProvider>
            <IeWarning>{children}</IeWarning>
        </BrowserContextProvider>
    );
};
