import React from 'react';
import Bowser, {Parser} from 'bowser';

export enum Browsers {
    Chrome = 'Chrome',
    Safari = 'Safari',
    MicrosoftEdge = 'Microsoft Edge',
    Firefox = 'Firefox',
    InternetExplorer = 'Internet Explorer',
    Opera = 'Opera',
}

export enum Oses {
    MacOS = 'macOS',
    Linux = 'Linux',
    Android = 'Android',
    Windows = 'Windows',
    IOS = 'IOS',
}

export enum Platforms {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
}

export interface BrowserContextInterface extends Parser.Parser {}

/**
 * The wp context definition
 */
export const BrowserContext = React.createContext<BrowserContextInterface>(
    {} as BrowserContextInterface,
);

/**
 * Props that need to be passed to the context provider
 */
export interface BrowserContextProviderProps {
    /**
     * The user agent you want to uses for the provider. If one is not provided
     * then `window.navigator.userAgent` will be used
     */
    userAgent?: string;
}

/**
 * Context provider to wrap your app in
 *
 * @see https://reactjs.org/docs/context.html#contextprovider
 */
export const BrowserContextProvider: React.FC<
    React.PropsWithChildren<BrowserContextProviderProps>
> = (props) => {
    const browser = Bowser.getParser(
        props.userAgent ||
            (typeof window === 'undefined'
                ? 'chrome'
                : window.navigator.userAgent),
    );

    return (
        <BrowserContext.Provider value={browser}>
            {props.children}
        </BrowserContext.Provider>
    );
};

/**
 * Export of a react hook use in functional components
 */
export const useBrowserContext = () => React.useContext(BrowserContext);
