exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-au-tsx": () => import("./../../../src/pages/au.tsx" /* webpackChunkName: "component---src-pages-au-tsx" */),
  "component---src-pages-ca-en-tsx": () => import("./../../../src/pages/ca-en.tsx" /* webpackChunkName: "component---src-pages-ca-en-tsx" */),
  "component---src-pages-ca-fr-tsx": () => import("./../../../src/pages/ca-fr.tsx" /* webpackChunkName: "component---src-pages-ca-fr-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-how-to-use-tsx": () => import("./../../../src/pages/how-to-use.tsx" /* webpackChunkName: "component---src-pages-how-to-use-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nl-tsx": () => import("./../../../src/pages/nl.tsx" /* webpackChunkName: "component---src-pages-nl-tsx" */),
  "component---src-pages-pl-tsx": () => import("./../../../src/pages/pl.tsx" /* webpackChunkName: "component---src-pages-pl-tsx" */)
}

